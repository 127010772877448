@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Proxima Nova, system-ui, sans-serif;
  }
}

.dropdown:hover > .dropdown-content {
  display: block;
}

button {
  outline: none;
}
.DayPicker-Day--monday {
  color: #00bcd4;
}

tr {
  height: 10px;
}
